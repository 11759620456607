<template lang="pug">
button.bg-white.h-9.w-9.hover_bg-reallylightblue.rounded-full.shadow.focus_outline-none.flex.items-center.justify-center(
  type='button',
  @click='$emit("activateMonitoring")'
)
  svg(
    v-if='isMonitoringButton'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  )
    path(
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9 0C9.55229 0 10 0.447715 10 1V2.07089C13.0657 2.5094 15.4906 4.93431 15.9291 8H17C17.5523 8 18 8.44771 18 9C18 9.55229 17.5523 10 17 10H15.9291C15.4906 13.0657 13.0657 15.4906 10 15.9291V17C10 17.5523 9.55229 18 9 18C8.44771 18 8 17.5523 8 17V15.9291C4.93431 15.4906 2.5094 13.0657 2.07089 10H1C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8H2.07089C2.5094 4.93431 4.93431 2.5094 8 2.07089V1C8 0.447715 8.44771 0 9 0ZM14 9C14 6.23858 11.7614 4 9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14C11.7614 14 14 11.7614 14 9Z'
      fill='#5477A9'
    )
  svg(
    v-else
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  )
    path(
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9 0C9.55229 0 10 0.447715 10 1V2.07089C13.0657 2.5094 15.4906 4.93431 15.9291 8H17C17.5523 8 18 8.44771 18 9C18 9.55229 17.5523 10 17 10H15.9291C15.81 10.833 15.5441 11.6188 15.1586 12.3302L13.6556 10.8272C13.8779 10.2612 14 9.64486 14 9C14 6.23858 11.7614 4 9 4C8.35514 4 7.73879 4.12208 7.17281 4.34438L5.66981 2.84138C6.38122 2.45588 7.16695 2.19005 8 2.07089V1C8 0.447715 8.44771 0 9 0ZM12.3302 15.1586C11.6188 15.5441 10.833 15.81 10 15.9291V17C10 17.5523 9.55229 18 9 18C8.44771 18 8 17.5523 8 17V15.9291C4.93431 15.4906 2.5094 13.0657 2.07089 10H1C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8H2.07089C2.19005 7.16695 2.45588 6.38122 2.84138 5.66981L1.33582 4.16421L2.75003 2.75L15.25 15.25L13.8358 16.6642L12.3302 15.1586ZM4.34438 7.17281C4.12208 7.73879 4 8.35514 4 9C4 11.7614 6.23858 14 9 14C9.64486 14 10.2612 13.8779 10.8272 13.6556L4.34438 7.17281Z'
      fill='#5477A9'
    )
</template>

<script>
export default {
  props: { isMonitoringButton: Boolean }
}
</script>
